<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <v-btn color="primary">
          Ga terug
        </v-btn>
      </div>
      <div class="text-center mb-8">
        <v-btn color="deep-purple" outlined @click="active = !active">
          {{ active ? "Menu verbergen" : "Menu laten zien" }}
        </v-btn>
      </div>
      <v-bottom-navigation style="margin-bottom:15px">
        <v-btn
          :color="activeElement === 'All' ? '' : ''"
          @click="activeElement = 'All'"
        >
          <span>Alles</span>

          <v-icon :color="activeElement === 'All' ? 'blue lighten-1' : ''"
            >mdi-domain</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Pending' ? '' : ''"
          @click="activeElement = 'Pending'"
        >
          <span>Wacht op goedkeuring</span>

          <v-icon :color="activeElement === 'Pending' ? 'blue lighten-1' : ''"
            >mdi-currency-eur</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Approved' ? '' : ''"
          @click="activeElement = 'Approved'"
        >
          <span>Goedgekeurd</span>

          <v-icon :color="activeElement === 'Approved' ? 'blue lighten-1' : ''">
            mdi-checkbox-marked-circle</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Cancelled' ? '' : ''"
          @click="activeElement = 'Cancelled'"
        >
          <span>Gegeannuleerd</span>

          <v-icon :color="activeElement === 'Cancelled' ? 'green dark' : ''"
            >mdi-cancel</v-icon
          >
        </v-btn>
      </v-bottom-navigation>

      <v-card v-if="activeElement === 'All'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="allAppointments"
          :search="search"
        ></v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Pending'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="pendingAppointments"
          :search="search"
        ></v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Approved'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="approvedAppointments"
          :search="search"
        ></v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Cancelled'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="cancelledAppointments"
          :search="search"
        ></v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api/api";

export default {
  data() {
    return {
      active: false,
      activeElement: "All",
      search: "",
      allAppointments: [],
      pendingAppointments: [],
      approvedAppointments: [],
      cancelledAppointments: [],
      headers: [
        {
          text: "Reserveringsnummer",
          value: "reservation_number",

          sortable: false,
        },

        {
          text: "Ingepland voor",
          value: "appointment_date",
          sortable: true,
          formatter: (value, key, item) =>
            item.appointment_date
              ? new Date(item.appointment_date).toLocaleDateString()
              : "",
        },

        {
          text: "van",
          value: "from_time",

          sortable: false,
        },

        {
          text: "tot",
          value: "to_time",
          sortable: false,
        },

        {
          text: "status",
          value: "status",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    api.getAllAppointments().then((data) => {
      this.allAppointments = data;
    });

    api.getPendingAppointments().then((data) => {
      this.pendingAppointments = data;
    });
    api.getApprovedAppointments().then((data) => {
      this.approvedAppointments = data;
    });

    api.getCancelledAppointments().then((data) => {
      this.cancelledAppointments = data;
    });
  },
};
</script>
